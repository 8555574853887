import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../utils/misc";

const buttonVariants = cva(
  "relative inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground hover:bg-primary/90 focus:bg-sky-950 active:bg-sky-950",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80 focus:bg-secondary/90 active:bg-secondary/90",
        outline:
          "border border-primary text-primary bg-card hover:bg-accent/90 hover:text-accent-foreground focus:bg-accent active:bg-accent",
        outlineSecondary:
          "border border-slate-200 text-slate-500 bg-slate-100 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-red-600 focus:bg-red-700 actibve:bg-red-700",
        success:
          "bg-green-700 text-white hover:bg-green-600 focus:bg-green-800 active:bg-green-800",
        ghost:
          "text-foreground hover:bg-accent/90 hover:text-accent-foreground/90 focus:bg-accent active:bg-accent",
        link: "text-secondary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3 py-1",
        xs: "h-8 rounded-md px-3 py-0.5 text-xs",
        lg: "h-11 rounded-md px-6 py-4",
        icon: "h-10 w-10",
      },
      shape: {
        default: "rounded-md",
        square: "rounded-none",
        circle: "rounded-full",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      shape: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, shape, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, shape, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
